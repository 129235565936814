body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #FF7043;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}
.input-number-base {
  width: 100%;
  height: 40px;
  justify-content: center;
}
.input-number-base .ant-input-number-input {
  height: 40px;
}
.color-primary {
   background-color: rgb(24, 58, 109);
}
.color-header {
  
  background-color: #009bdc;
  /* background-color: #33b4c2; */
}
.custom-select {
  width: 100%;
  height: 40px;
}
.height-body {
  min-height: calc(100vh - 96 - 20);
}
.input-number-base1 {
  height: 40px;
}
.input-number-base1 .ant-input-number-input {
  height: 40px;
}
.font-weight-bold {
  font-weight:800;
}
.label-modal {
  margin-top: 12px;
}
.custom-segmented .ant-segmented-item-selected {
  background-color: #1890ff; /* Màu xanh bạn muốn khi item được chọn */
  color: white; /* Màu chữ của item khi được chọn */
  border-color: #1890ff; /* Để viền item có cùng màu */
}

.cus-input-label {
  color: #777;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 12px;
}

.cus-input {
  border: 1px solid #B9BBBD;
  height: 30px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 13px;
  color: black;
}
.custom-form-item .ant-form-item-label {
  margin-bottom: -5px !important; /* Điều chỉnh khoảng cách phía dưới của label */
}

.custom-form-item .ant-form-item-control {
  margin-top: 0; /* Loại bỏ khoảng cách phía trên của input */
}