.footer-table {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.value-column {
    text-align: right;
    font-weight: 700;
    padding-right: 8px;
}

.table-book {
    width: 100%;
    margin-bottom: 20px;
}
.row-table-book {
    
}

.table-book .ant-table-tbody {
    padding: 0 !important;
}

.table-book .ant-table-tbody>tr>td {
    padding: 0 !important;
    height: 40px;
}

.txt-title-footer {
    font-size: 1rem;
    font-weight: 800;
}
.format-number-table {
    font-size: 1rem;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: right;
    padding-right: 6px;
}
.min-height {
    min-height: calc(100vh - 220px);
}
.custom-table .ant-table-thead > tr > th {
    background-color: #049bdc; /* Màu nền bạn muốn */
    color: #fff; 
    font-size: 16px;
    height: 40px;              /* Giảm độ cao của dòng tiêu đề */
    line-height: 10px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
  }
  .custom-table .ant-table-tbody > tr > td {
    color: #000;           /* Màu chữ bạn muốn */
    font-size: 15px;       /* Cỡ chữ bạn muốn */
    background-color: #ffffff; /* Màu nền bạn muốn */
  }
  .custom-table-task .ant-table-thead > tr > th {
    background-color: #babbbb; /* Màu nền bạn muốn */
    color: #fff; 
    font-size: 16px;
    height: 40px;              /* Giảm độ cao của dòng tiêu đề */
    line-height: 10px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
  }